import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import { Grid, Margin } from "@components/atoms"
import { GridItem, Tags } from "@components/molecules"
import { RelatedPosts, TransitionMask } from "@components/organisms"
import { RelatedProps } from "@components/organisms/RelatedPosts"
import { WithData } from "@utils"
import { ProjectProps } from "@types"

const ProjectTemplate = ({
  pageContext: { settings },
  data: {
    sanityPost: {
      title,
      slug,
      description,
      shareImage,
      gridItems,
      tags,
      backgroundColor,
    },
    sanityPage,
  },
}: ProjectProps): React.ReactElement => {
  const projectSettings = {
    ...settings,
    subtitle: title,
    shareImage: shareImage || settings.shareImage,
    keywords: tags ? tags.map(tag => tag.tag).join(", ") : settings.keywords,
    description: description || settings.description,
  }
  const nodes = sanityPage.gridItems
  if (!nodes || nodes.length < 1) return <></>

  const parsedTitle = "Studio Elana Schlenker: " + title
  const meta = [
    { property: "og:title", content: parsedTitle },
    { name: "twitter:title", content: parsedTitle },
    { name: "description", content: description },
    { name: "twitter:description", content: description },
    { property: "og:description", content: description },
  ]

  let previousPost: RelatedProps | undefined = undefined
  let nextPost: RelatedProps | undefined = undefined
  const filteredNodes = nodes.filter(node => node.post && node.post.slug)
  filteredNodes.map((node: any, i: number) => {
    if (node.post.slug.current === slug.current) {
      previousPost =
        i === 0
          ? filteredNodes[filteredNodes.length - 1].post
          : filteredNodes[i - 1].post
      nextPost =
        i === filteredNodes.length - 1
          ? filteredNodes[0].post
          : filteredNodes[i + 1].post
    }
  })

  const isFullBleed =
    (gridItems[0]?.layout && gridItems[0]?.layout?.fullBleed) ?? false

  return (
    <>
      <Helmet {...{ meta }} title={parsedTitle}>
        <title>{parsedTitle}</title>
      </Helmet>

      <Main backgroundColor={backgroundColor?.hex}>
        <ProjectTitle hasFullBleed={gridItems[0]?.layout?.fullBleed}>
          {title}
        </ProjectTitle>
        <Margin>
          <Grid {...{ isFullBleed }}>
            {gridItems.map(item => (
              <GridItem
                pageBackgroundColor={backgroundColor?.hex}
                key={item._key}
                {...item}
              />
            ))}
          </Grid>
        </Margin>

        <Margin>
          <Tags {...{ tags }} />
        </Margin>

        {previousPost && nextPost && (
          <RelatedPosts {...{ previousPost, nextPost }} />
        )}
      </Main>

      <TransitionMask />
    </>
  )
}

const Main = styled.main<{ backgroundColor?: string }>`
  background-color: ${props => props.backgroundColor ?? "#fef3d9"};

  @media only screen and (max-width: 767px) {
    padding-bottom: 30vh;
  }
`

const ProjectTitle = styled.h1<{ hasFullBleed: boolean }>`
  margin: 0 auto;
  padding: 2vw 20px 2vw 20px;
  font-size: 7vw;
  @media only screen and (min-width: 768px) {
    padding: 0.7vw 20px 0.9vw 20px;
    font-size: 4vw;
  }
  line-height: 1.05em;
  border-top: 2px solid black;
  ${props => !props.hasFullBleed && `border-bottom: 2px solid black;`}
  text-align: center;
`

const mapDataToProps = ({ sanityPost }: any) => ({ ...sanityPost })

export const query = graphql`
  query project($slug: String!) {
    sanityPost(slug: { current: { eq: $slug } }) {
      slug {
        current
      }
      title
      description
      backgroundColor {
        hex
      }
      shareImage {
        asset {
          url
        }
      }
      tags {
        tag
        tagSet {
          category
          color {
            hex
          }
        }
      }
      gridItems {
        ... on SanityGridItem {
          ...gridItemFields
        }
      }
    }
    sanityPage(slug: { current: { eq: "home" } }) {
      gridItems {
        post {
          title
          slug {
            current
          }
          shareImage {
            asset {
              url
            }
          }
        }
      }
    }
  }
`

export default WithData(mapDataToProps, ProjectTemplate)
